import { AlertResponse, Media, RoomSettingsMedia } from "../types/media";
import styled from "styled-components";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import FixaImage from "@coworker/apprestructured/src/shared/wrappers/FixaImage/FixaImage";
import {
  fullMediaName,
  getMainAndAlternativeProducts,
  getMediaTypeText,
} from "../tools";
import { TaskLinks } from "../types/taskLink";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";
import { useTranslation } from "@coworker/locales";
import { MediaListItemCardDesktop } from "../styles/styles";
import FixaLink from "@coworker/apprestructured/src/shared/wrappers/FixaLink/FixaLink";
import { useImagesByMediaId } from "../hooks/useImagesByMediaId";
import { MediaTypes } from "../constants";

const ImageContainer = styled('div')<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 226px;
  overflow: hidden;
  .image--fallback {
    background-color: ${({ active }) => (active ? "#F5F5F5" : "#FFFFFF")};
  }
`;

const StyledImage = styled(FixaImage)<{ rotation: number }>`
  object-fit: cover;
  object-position: center center;
  transform: rotate(${({ rotation }) => rotation}deg);
  height: 100%;
  width: 100%;
`;

const ImageAndNameContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 262px;
`;

const NameText = styled('div')`
  font-size: 16px;
  font-weight: 700;
  color: black;
  line-height: 24px;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
`;

const ArticleInfoBarContainer = styled('div')`
  display: block;
  float: left;
  width: 100%;
  height: 22px;
  margin-top: 8px;
`;

const ArticleInfoTextBox = styled('div')<{
  $color: string;
  $backgroundColor: string;
}>`
  display: block;
  float: left;
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 8px;
`;

const MediaTypeText = styled('div')`
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #484848;
  background-color: #f5f5f5;
  line-height: 18px;
  padding: 2px 8px;
`;

const MediaBarContainer = styled('div')`
  display: flex;
  float: left;
  margin-top: 25px;
  justify-content: space-between;
  width: 100%;
  height: 44px;
`;

const MediaInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ArticleCountText = styled('div')`
  font-size: 14px;
  font-weight: 700;
  color: #484848;
  line-height: 22px;
`;

const StyleGroupText = styled('div')`
  font-size: 14px;
  font-weight: 400;
  color: #484848;
  line-height: 22px;
`;

const SwitchContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ActiveLabel = styled('label')`
  font-size: 8px;
  font-weight: 400;
  color: #484848;
  line-height: 12px;
  margin-bottom: 4px;
`;

interface MediaListItemDesktopProps {
  media: Media;
  alert: AlertResponse | undefined;
  tasks: TaskLinks | undefined;
  onSaveMedia: (media: Media) => void;
}

export function MediaListItemDesktop({
  media,
  alert,
  tasks,
  onSaveMedia,
}: MediaListItemDesktopProps) {
  const { t } = useTranslation();
  const { data: images } = useImagesByMediaId(media.id);
  const signedUrlImages = useImagesBySignedUrl(images ?? []);

  const onActiveChange = () => {
    media.active = media.active === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    onSaveMedia(media);
  };

  const imageUrl = signedUrlImages[0]?.url ?? "";
  const imageRotation = signedUrlImages[0]?.rotation ?? 0;
  const mainAndAlternativeProduct = getMainAndAlternativeProducts(media);
  return (
    <MediaListItemCardDesktop
      data-testid={
        "mediaListItemCardDesktop:" +
        fullMediaName(
          media.name,
          mainAndAlternativeProduct.mainProductName1,
          mainAndAlternativeProduct.mainProductName2
        )
      }
      active={media.active === "ACTIVE"}
    >
      <FixaLink to={`/mediamaintenance/tabs/${media.id}?tab=article-list-tab`}>
        <ImageAndNameContainer>
          <ImageContainer active={media.active === "ACTIVE"}>
            <StyledImage src={imageUrl} alt="" rotation={imageRotation} />
          </ImageContainer>
          <NameText>
            {fullMediaName(
              media.name,
              mainAndAlternativeProduct.mainProductName1,
              mainAndAlternativeProduct.mainProductName2
            )}
          </NameText>
          <MediaTypeText>
            {getMediaTypeText(media.mediaType as MediaTypes)}
          </MediaTypeText>
        </ImageAndNameContainer>
        <ArticleInfoBarContainer>
          {alert !== undefined && alert.salesStop > 0 && (
            <ArticleInfoTextBox
              $color="#E00751"
              $backgroundColor="#FCE6EE"
            >{`${alert.salesStop} Sale stop`}</ArticleInfoTextBox>
          )}
          {alert !== undefined && alert.eds > 0 && (
            <ArticleInfoTextBox
              $color="#CA5008"
              $backgroundColor="#F2BEA7"
            >{`${alert.eds} EDS`}</ArticleInfoTextBox>
          )}
          {tasks !== undefined && tasks.length > 0 && (
            <ArticleInfoTextBox
              $color="#0A8A00"
              $backgroundColor="#E6F3E5"
            >{`${tasks.length} Tasks`}</ArticleInfoTextBox>
          )}
        </ArticleInfoBarContainer>
        <MediaBarContainer>
          <MediaInfo>
            <ArticleCountText>
              {t("countArticlesString", {
                count: parseInt(`${media?.nbrArticles ?? 0}`),
              })}
            </ArticleCountText>
            <StyleGroupText>
              {(media as RoomSettingsMedia)?.styleGroup}
            </StyleGroupText>
          </MediaInfo>
          <SwitchContainer>
            <ActiveLabel htmlFor="active">{t("activeString")}</ActiveLabel>
            <FixaSwitch
              id={"active"}
              checked={media.active === "ACTIVE"}
              value={media.active}
              onChange={onActiveChange}
              disabled={!media.name}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </SwitchContainer>
        </MediaBarContainer>
      </FixaLink>
    </MediaListItemCardDesktop>
  );
}
