import React, { useState } from "react";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { Trans, useTranslation } from "@coworker/locales";
import styles from "./login-view.module.css";
import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { tokenScopes } from "../../../assets/auth/msalConfig";
import { useMsal } from "@azure/msal-react";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";
import { reportMessageToSentry } from "@coworker/app/src/hooks/useConfigureSentry";
import { useWorkspacesState } from "@coworker/app/src/hooks/useWorkspaces";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";

export function LoginView({
  authError,
  authInProgress,
}: {
  authError: string;
  authInProgress: boolean;
}) {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const { navigationState } = useWorkspacesState();
  const [signInState, setSignInState] = useState("");

  const handleLogin = async () => {
    setSignInState("Loading...");
    const userAgent = navigator.userAgent;
    reportMessageToSentry("Login button clicked: ", {
      level: "warning",
      extra: {
        userAgent: userAgent,
        navigationState: navigationState,
      },
    });

    try {
      await instance.loginRedirect({
        scopes: tokenScopes,
      });
    } catch (error: any) {
      if (error.name === "BrowserAuthError") {
        console.error("BrowserAuthError occurred:", error);
        reportMessageToSentry("BrowserAuthError during login", {
          level: "error",
          extra: {
            userAgent: userAgent,
            errorMessage: error.message,
            errorStack: error.stack,
          },
        });
      } else {
        setSignInState("Login failed. Please try again.");
        reportMessageToSentry("Login failed", {
          level: "error",
          extra: {
            userAgent: userAgent,
            errorMessage: error.message,
            errorStack: error.stack,
          },
        });
      }
    }
  };

  const { push } = useWorkspacesAction() || {};
  return authInProgress ? (
    <SimpleLoadingBall text="Loading..." size="medium" />
  ) : (
    <FullScreenPopup noPadding noAppBar>
      <div className={styles["main-container"]}>
        <div className={styles["outer-container"]}>
          <div className={styles["logo-text-container"]}>
            <FixaText
              className={styles["text-container"] || ""}
              tagName="h1"
              headingSize="l"
              bodySize="l"
            >
              Fixa
            </FixaText>
            <FixaText
              className={styles["text-container-small"] || ""}
              tagName="p"
              headingSize="s"
              bodySize="m"
            >
              Simplifying your daily routines
            </FixaText>
          </div>
          <div className={styles["login-privacy-container"]}>
            <div>
              <FixaButton
                aria-label={t("login")}
                onClick={handleLogin}
                type="primary"
                text={authInProgress ? "Signing In" : <Trans>login</Trans>}
                disabled={authInProgress}
              />
            </div>
            <div className={styles["privacy-container"]}>
              <FixaButton
                onClick={() => push("/privacy")}
                type="tertiary"
                text={<Trans>privacyString</Trans>}
              />
            </div>
            {signInState ||
              (authError && (
                <div className={styles["login-privacy-container"]}>
                  {signInState}
                </div>
              ))}
          </div>
        </div>
      </div>
    </FullScreenPopup>
  );
}
