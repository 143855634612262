
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { getMediasCountTranslation } from "../tools";
import { PaddedContainer } from "../styles/styles";

interface MediasCountTextProps {
  mediasCount: number | undefined;
  searchCount?: number;
}

export function MediasCountText({
  mediasCount,
  searchCount,
}: MediasCountTextProps) {
  const { t } = useTranslation();
  let countText = `${t("viewingString")} -`;

  if (mediasCount !== undefined) {
    const settingsOrSetting = t(getMediasCountTranslation(mediasCount));
    const searchCounter =
      !searchCount || mediasCount === searchCount
        ? mediasCount
        : `${searchCount}/${mediasCount}`;
    countText = `${t("viewingString")} ${searchCounter} ${settingsOrSetting}`;
  }

  return (
    <PaddedContainer $py="8px" $px="24px">
      <Text text={countText} grey />
    </PaddedContainer>
  );
}
