import { ReactNode } from "react";
import Text from "@ingka/text";

import "@ingka/text/dist/style.css";

interface TextProps {
  headingSize?: "xs" | "s" | "m" | "l";
  className?: string;
  children?: ReactNode;
  tagName?: "h1" | "h2" | "h3" | "h4" | "p";
  bodySize?: "m" | "l";
  dangerouslySetInnerHTML?: { __html: string };
}

export const FixaText = (props: TextProps) => {
  return <Text {...props}>{props.children}</Text>;
};
