import { AlertResponse, Media } from "../types/media";
import { TaskLinks } from "../types/taskLink";
import { MediasViewListMobile } from "./MediasViewListMobile";
import { MediasViewNoSearchResult } from "../Atoms/MediasViewNoSearchResult";
import { MediasViewArticleListMobile } from "./MediasViewArticleListMobile";
import { GreyRow } from "../styles/styles";
import { Trans } from "@coworker/locales";
import { ArticleWithMediaCount } from "../types/article";
import { FixaText } from "@coworker/apprestructured/src/shared/wrappers/FixaText/FixaText";

interface MediasViewSearchListMobileProps {
  medias: Media[] | undefined;
  query: string;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
  storeId: string;
  articles: ArticleWithMediaCount[] | undefined;
}

export function MediasViewSearchListMobile({
  medias,
  query,
  taskLinks,
  alerts,
  storeId,
  articles,
}: MediasViewSearchListMobileProps) {
  return (
    <>
      {!!medias &&
        medias.length === 0 &&
        !!articles &&
        articles.length === 0 && (
          <MediasViewNoSearchResult query={query ?? ""} />
        )}

      {!!medias && medias.length > 0 && (
        <GreyRow>
          <FixaText>
            <b>
              <Trans count={medias?.length ?? 0}>countMediaString</Trans>
            </b>
          </FixaText>
        </GreyRow>
      )}
      <MediasViewListMobile
        medias={medias}
        taskLinks={taskLinks}
        alerts={alerts}
      />

      {!!articles && articles.length > 0 && (
        <GreyRow>
          <FixaText>
            <b>
              <Trans count={articles?.length ?? 0}>countArticlesString</Trans>
            </b>
          </FixaText>
        </GreyRow>
      )}
      <MediasViewArticleListMobile
        articles={articles}
        taskLinks={taskLinks}
        storeId={storeId}
      />
    </>
  );
}
