
import styled from "styled-components";
import FixaStatus from "@coworker/apprestructured/src/shared/wrappers/FixaStatus/FixaStatus";
import { useTranslation } from "@coworker/locales";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import { TaskLinks } from "../types/taskLink";
import { AlertResponse, Media } from "../types/media";
import { TabIds, TabQueryKey } from "../Organisms/MediaTabs.helper";
import { useImagesByMediaId } from "../hooks/useImagesByMediaId";
import {
  CenterContainer,
  Column,
  FullLine,
  FullSpaceAnchor,
  LeftContainer,
  Row,
} from "../styles/styles";
import FixaImage from "@coworker/apprestructured/src/shared/wrappers/FixaImage/FixaImage";
import { MediaTypes } from "../constants";
import { Text } from "../Atoms/Text";
import {
  getEDSAlertCount,
  getOngoingTaskCount,
  getSalesStopAlertCount,
} from "./MediaListItem.helper";
import { getMediaTypeText } from "../tools";

const SolidStatus = styled(FixaStatus)`
  .status__dot {
    background-color: currentColor;
  }
  font-size: 12px;
`;

const LeftContainerWithMargin = styled(LeftContainer)`
  margin: 0px 16px;
`;

const ImageContainer = styled('div')<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 104px;
  overflow: hidden;
  .image--fallback {
    background-color: ${({ active }) => (active ? "#F5F5F5" : "#FFFFFF")};
  }
`;

const MediaName = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  color: var(--black);
`;

const StyledImage = styled(FixaImage)<{ rotation: number }>`
  object-fit: cover;
  object-position: center center;
  transform: rotate(${({ rotation }) => rotation}deg);
  height: 100%;
  width: 100%;
`;

const StatusRow = styled(Row)`
  margin: 2px 0px 8px 0px;
  margin-bottom: 8px;
  vertical-align: middle;
`;

const Container = styled('div')<{
  active: boolean;
}>`
  padding: 24px 0px;
  width: 100%;
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#F5F5F5")};
`;

const ArticleCountText = styled('div')`
  font-size: 14px;
  font-weight: 700;
  color: #484848;
  line-height: 22px;
  width: 100%;
`;

const ArticleInfoBarContainer = styled('div')`
  display: block;
  float: left;
  width: 100%;
  height: 22px;
  margin-top: 8px;
`;

const ArticleInfoTextBox = styled('div')<{
  $color: string;
  $backgroundColor: string;
}>`
  display: block;
  float: left;
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 8px;
`;

interface MediaListItemProps {
  media: Media;
  taskLinks: TaskLinks;
  alerts: AlertResponse[];
}

export function MediaListItemMobile({
  media,
  taskLinks,
  alerts,
}: MediaListItemProps) {
  const { t } = useTranslation();

  const { data: images } = useImagesByMediaId(media.id);
  const signedUrlImages = useImagesBySignedUrl(images ?? []);

  const ongoingTaskCount = getOngoingTaskCount(taskLinks, media.id);
  const edsAlertCount = getEDSAlertCount(alerts, media.id);
  const salesStopAlertCount = getSalesStopAlertCount(alerts, media.id);

  const mainImageUrl = signedUrlImages[0]?.url || "";
  const imageRotation = signedUrlImages[0]?.rotation ?? 0;

  return (
    <>
      <FullSpaceAnchor
        href={`/mediamaintenance/tabs/${media.id}?${TabQueryKey}=${TabIds.LIST}`}
      >
        <Container active={media.active === "ACTIVE"}>
          <Row>
            <LeftContainerWithMargin>
              <ImageContainer active={media.active === "ACTIVE"}>
                <StyledImage
                  src={mainImageUrl}
                  alt=""
                  rotation={imageRotation}
                />
              </ImageContainer>
            </LeftContainerWithMargin>
            <Column>
              <CenterContainer $centerText={false}>
                <StatusRow>
                  <Text
                    size={"small"}
                    text={getMediaTypeText(media.mediaType as MediaTypes)}
                  />
                  &nbsp;&nbsp;
                  {media.active === "INACTIVE" && (
                    <SolidStatus
                      label={t("inactiveString")}
                      statusColor="red"
                      small={true}
                    />
                  )}
                  {media.active === "ACTIVE" && (
                    <SolidStatus
                      label={t("activeString")}
                      statusColor="green"
                      small={true}
                    />
                  )}
                </StatusRow>
              </CenterContainer>
              <CenterContainer $centerText={false}>
                <MediaName>{media.name}</MediaName>
                <ArticleCountText>
                  {t("countArticlesString", {
                    count: parseInt(`${media?.nbrArticles ?? 0}`),
                  })}
                </ArticleCountText>
                <ArticleInfoBarContainer>
                  {salesStopAlertCount > 0 && (
                    <ArticleInfoTextBox
                      $color="#E00751"
                      $backgroundColor="#FCE6EE"
                    >{`${salesStopAlertCount} Sale stop`}</ArticleInfoTextBox>
                  )}
                  {edsAlertCount > 0 && (
                    <ArticleInfoTextBox
                      $color="#CA5008"
                      $backgroundColor="#F2BEA7"
                    >{`${edsAlertCount} EDS`}</ArticleInfoTextBox>
                  )}
                  {ongoingTaskCount > 0 && (
                    <ArticleInfoTextBox
                      $color="#0A8A00"
                      $backgroundColor="#E6F3E5"
                    >{`${ongoingTaskCount} Tasks`}</ArticleInfoTextBox>
                  )}
                </ArticleInfoBarContainer>
              </CenterContainer>
            </Column>
          </Row>
        </Container>
        <FullLine margin={"0"} />
      </FullSpaceAnchor>
    </>
  );
}
