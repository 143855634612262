import { useState,ChangeEvent } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import { MediasViewSearchListDesktop } from "../Organisms/MediasViewSearchListDesktop";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { CreateSettingsModal } from "../Organisms/Modals/CreateSettingsModal";
import { Media } from "../types/media";
import { MessageModal } from "../Organisms/Modals/MessageModal";
import { useUpdateMediaWithId } from "../hooks/useUpdateMedia";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { MediasViewListDesktop } from "../Organisms/MediasViewListDesktop";
import { useSearchArticlesInMedias } from "../hooks/useSearchArticlesInMedias";
import { useMatchingMedias } from "../../../hooks/useMatchingMedias";
import { FixaWindow } from "@coworker/reusable";
import { countMediaTypes, MediasViewTabs } from "../Molecules/MediasViewTabs";
import { useFilteredMedias } from "../hooks/useFilteredMedias";
import { FilterPanel } from "@coworker/apprestructured/src/shared/filters/components";
import { useMediaOverviewFilters } from "../hooks/useMediaOverviewFilters";
import { mapUniqueMediaAreas } from "../Helpers/mapUniqueMediaAreas";

const ScrollContainer = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 64px 64px 64px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

const ToolbarContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const HeaderTitleText = styled('div')`
  font-size: 24px;
  font-weight: 700;
`;

const SearchResultCountText = styled('div')`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 8px;
`;

const FilterWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export function MediasViewDesktop() {
  const [showModalMessage, setShowModalMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [displayedQuery, setDisplayedQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMediaTypeTab, setSelectedMediaTypeTab] =
    useState<string>("allMedia");

  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: medias, isError } = useMediasByStoreId(storeId);
  const { data: alerts } = useAlertsByStore(storeId);
  const updateMediaMutation = useUpdateMediaWithId();
  const { data: articles } = useSearchArticlesInMedias(storeId, searchQuery);
  const matchingMedias = useMatchingMedias(medias, searchQuery);
  const uniqueMediaAreas = mapUniqueMediaAreas(medias ?? []);
  const mediaOverviewFilters = useMediaOverviewFilters(uniqueMediaAreas);
  const tasks = useTasksByStore(storeId);
  const filteredMedias = useFilteredMedias(
    medias,
    alerts,
    tasks,
    selectedMediaTypeTab,
    mediaOverviewFilters.filterValues
  );
  const mediaTypesAndCount = countMediaTypes(medias || []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[.,]/g, "");
    setDisplayedQuery(inputValue);
    setSearchQuery(sanitizedInput);
  };

  const onClear = () => {
    setDisplayedQuery("");
    setSearchQuery("");
  };

  const enableMedia = (window as FixaWindow).enableMediaTypes;
  const enableMediaLandingFilters = (window as FixaWindow)
    .enableMediaLandingFilters;

  const onSaveMedia = async (media: Media) => {
    try {
      const { nbrArticles: nbr_articles, pieces, ...saveMedia } = media;
      await updateMediaMutation.mutateAsync(saveMedia);
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotUpdateRoomTryAgainString"));
    }
  };

  const onOngoingTasksClick = () => {
    push(`/mediamaintenance/tasks`);
  };

  // No medias
  if (medias && medias.length === 0) {
    return (
      <>
        <MessageAndButton
          keyForMessageText={
            enableMedia
              ? "noMediasCreatedString"
              : "noRoomSettingsCreatedString"
          }
          keyForButtonText={
            enableMedia ? "addMediaString" : "addRoomSettingsString"
          }
          buttonAction={async () => {
            if (enableMedia) {
              push(`/mediamaintenance/create`);
            } else {
              setShowModal(true);
            }
          }}
        />
        <CreateSettingsModal
          storeId={storeId}
          isVisible={showModal}
          onClose={(mediaId) => {
            setShowModal(false);
            if (mediaId) {
              push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
            }
          }}
        />
      </>
    );
  }

  if (isError) {
    return (
      <MessageAndButton
        keyForMessageText={"couldNotLoadMediasString"}
        keyForButtonText={"tryAgainString"}
        buttonAction={async () => {
          window.location.reload();
        }}
      />
    );
  }

  const doSearch = searchQuery.length > 1;

  return (
    <>
      <ScrollContainer>
        <ToolbarContainer>
          <HeaderTitleText>{t("mediaMaintenanceString")}</HeaderTitleText>
          <FixaButton
            aria-label={t("addMediaString")}
            text={
              enableMedia ? t("addMediaString") : t("addRoomSettingsString")
            }
            type="primary"
            data-testid="addRoomSettingsButton"
            onClick={() => {
              if (enableMedia) {
                push(`/mediamaintenance/create`);
              } else {
                setShowModal(true);
              }
            }}
          />
        </ToolbarContainer>
        <MediasViewTabs
          mediaTypesAndCount={mediaTypesAndCount}
          totalCount={medias?.length ?? 0}
          onTabChange={setSelectedMediaTypeTab}
        />
        <ToolbarContainer>
          <FilterWrapper>
            {enableMediaLandingFilters ? (
              <div>
                <FilterPanel
                  filterValues={mediaOverviewFilters.filterValues}
                  setFilterValues={mediaOverviewFilters.setFilterValues}
                  useFiltersFactory={mediaOverviewFilters.useFiltersFactory}
                />
              </div>
            ) : (
              <div />
            )}
            <div>
              <FixaSearch
                id="media-search"
                value={displayedQuery}
                onChange={onChange}
                onClear={onClear}
                placeholder={t("mediasViewSearch")}
                ariaLabel={t("mediasViewSearch")}
                style={{ width: "310px" }}
              />
              {matchingMedias?.length! > 0 && (
                <SearchResultCountText>
                  {doSearch
                    ? t("searchResultString", {
                        count:
                          (matchingMedias?.length ?? 0) +
                          (articles?.length ?? 0),
                      })
                    : "\xa0"}
                </SearchResultCountText>
              )}
            </div>
          </FilterWrapper>
        </ToolbarContainer>

        {doSearch ? (
          <MediasViewSearchListDesktop
            medias={matchingMedias}
            alerts={alerts}
            taskLinks={tasks.taskLinks}
            onSaveMedia={onSaveMedia}
            onOngoingTasksClick={onOngoingTasksClick}
            query={searchQuery}
            storeId={storeId}
            articles={articles ?? undefined}
          />
        ) : (
          <MediasViewListDesktop
            medias={filteredMedias}
            alerts={alerts}
            taskLinks={tasks.taskLinks}
            onSaveMedia={onSaveMedia}
            onOngoingTasksClick={onOngoingTasksClick}
          />
        )}
      </ScrollContainer>

      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      <CreateSettingsModal
        storeId={storeId}
        isVisible={showModal}
        onClose={(mediaId) => {
          setShowModal(false);
          if (mediaId) {
            push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
          }
        }}
      />
    </>
  );
}
