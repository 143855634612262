import React from "react";
import styled from "styled-components";
import { FixaText } from "@coworker/apprestructured/src/shared/wrappers/FixaText/FixaText";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import PencilIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PencilIconPath";
import { FixaCommercialMessage } from "@coworker/apprestructured/src/shared/wrappers/FixaCommercialMessage/FixaCommercialMessage";
import { getMediaTypeText } from "../tools";
import { MediaTypes } from "../constants";
import { useTranslation } from "@coworker/locales";

interface MediaHeaderProps {
  title: string | React.ReactNode;
  type: MediaTypes;
  onEditClick: () => void;
}

const MediaHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 8px;
  background-color: var(--grey100);
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  flex-grow: 1;
  min-width: 0;
`;

const TypeMessageWrapper = styled('div')`
  display: inline-block;
  margin-bottom: 8px;
`;

const TypeMessage = styled(FixaCommercialMessage)`
  font-weight: normal;
  background-color: #0058a3;
`;

const TitleText = styled(FixaText)`
  word-wrap: break-word;
  flex-shrink: 1;
`;

export const MediaHeader = ({ title, type, onEditClick }: MediaHeaderProps) => {
  const { t } = useTranslation();
  return (
    <MediaHeaderWrapper>
      <TextWrapper>
        <TypeMessageWrapper>
          <TypeMessage message={getMediaTypeText(type)} />
        </TypeMessageWrapper>
        <TitleText tagName={"h1"} headingSize={"m"} children={title} />
      </TextWrapper>
      <FixaButton
        text={t("editString")}
        ssrIcon={PencilIconPath}
        iconPosition={"trailing"}
        type={"secondary"}
        size={"xsmall"}
        onClick={onEditClick}
      />
    </MediaHeaderWrapper>
  );
};
