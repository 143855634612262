import { useState,ChangeEvent } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { TwoLinedGreyBox } from "../Molecules/TwoLinedGreyBox";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import { MediasViewListMobile } from "../Organisms/MediasViewListMobile";
import { MediasViewSearchListMobile } from "../Organisms/MediasViewSearchListMobile";
import { MediasCountText } from "../Atoms/MediasAmountText";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { CreateSettingsModal } from "../Organisms/Modals/CreateSettingsModal";
import { useSearchArticlesInMedias } from "../hooks/useSearchArticlesInMedias";
import { SearchResultCountText } from "../Atoms/SearchResultCountText";
import { SearchBarContainer } from "../styles/styles";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { useMatchingMedias } from "../../../hooks/useMatchingMedias";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { FixaWindow } from "@coworker/reusable";
import { MediasViewTabs, countMediaTypes } from "../Molecules/MediasViewTabs";
import { useFilteredMedias } from "../hooks/useFilteredMedias";
import { FilterPanel } from "@coworker/apprestructured/src/shared/filters/components";
import { useMediaOverviewFilters } from "../hooks/useMediaOverviewFilters";
import { mapUniqueMediaAreas } from "../Helpers/mapUniqueMediaAreas";

const ApplicationFooterActionBar = styled('div')`
  position: fixed;
  bottom: 68px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-block: 10px;
  background-color: var(--white);
  border-top: 1px solid var(--grey200);
`;

const ScrollContainerEditableSettings = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100% - 76px);
`;

const HorizontalLine = styled('hr')`
  height: 1px;
  background-color: var(--grey200);
`;

export function MediasViewMobile() {
  const [searchQuery, setSearchQuery] = useState("");
  const [displayedQuery, setDisplayedQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMediaTypeTab, setSelectedMediaTypeTab] =
    useState<string>("allMedia");
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: medias, isError } = useMediasByStoreId(storeId);
  const { data: articles } = useSearchArticlesInMedias(storeId, searchQuery);
  const { data: alerts } = useAlertsByStore(storeId);
  const uniqueMediaAreas = mapUniqueMediaAreas(medias ?? []);
  const mediaOverviewFilters = useMediaOverviewFilters(uniqueMediaAreas);
  const matchingMedias = useMatchingMedias(medias, searchQuery);
  const tasks = useTasksByStore(storeId);
  const filteredMedias = useFilteredMedias(
    medias,
    alerts,
    tasks,
    selectedMediaTypeTab,
    mediaOverviewFilters.filterValues
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[.,]/g, "");
    setDisplayedQuery(inputValue);
    setSearchQuery(sanitizedInput);
  };

  const onClear = () => {
    setDisplayedQuery("");
    setSearchQuery("");
  };

  const mediaTypesAndCount = countMediaTypes(medias || []);

  const enableMedia = (window as FixaWindow).enableMediaTypes;
  const enableMediaLandingFilters = (window as FixaWindow)
    .enableMediaLandingFilters;

  const linkbar = (
    <Linkbar currentLabel={t("mediaMaintenanceString")} currentAsH1 />
  );

  // No medias
  if (medias && medias.length === 0) {
    return (
      <>
        {linkbar}
        <MessageAndButton
          keyForMessageText={
            enableMedia
              ? "noMediasCreatedString"
              : "noRoomSettingsCreatedString"
          }
          keyForButtonText={
            enableMedia ? "addMediaString" : "addRoomSettingsString"
          }
          buttonAction={async () => {
            if (enableMedia) {
              push(`/mediamaintenance/create`);
            } else {
              setShowModal(true);
            }
          }}
        />
        <CreateSettingsModal
          storeId={storeId}
          isVisible={showModal}
          onClose={(mediaId) => {
            setShowModal(false);
            if (mediaId) {
              push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
            }
          }}
        />
      </>
    );
  }

  if (isError) {
    return (
      <MessageAndButton
        keyForMessageText={"couldNotLoadMediasString"}
        keyForButtonText={"tryAgainString"}
        buttonAction={async () => {
          window.location.reload();
        }}
      />
    );
  }

  return (
    <>
      <ScrollContainerEditableSettings>
        {linkbar}
        <TwoLinedGreyBox
          tabIndex={0}
          role="button"
          ariaLabel={t("ongoingTasksAriaLabelString", { count: tasks.count })}
          headLine={t("ongoingTasksString")}
          bottomleftText={`${tasks.count}`}
          rightComponent={<RightArrow />}
          onClick={() => {
            push(`/mediamaintenance/tasks`);
          }}
        />
        <MediasViewTabs
          mediaTypesAndCount={mediaTypesAndCount}
          totalCount={medias?.length || 0}
          onTabChange={setSelectedMediaTypeTab}
          mobile={true}
        />
        {enableMediaLandingFilters && (
          <div>
            <HorizontalLine />
            <FilterPanel
              filterValues={mediaOverviewFilters.filterValues}
              setFilterValues={mediaOverviewFilters.setFilterValues}
              useFiltersFactory={mediaOverviewFilters.useFiltersFactory}
            />
            <HorizontalLine />
          </div>
        )}
        <SearchBarContainer>
          <FixaSearch
            id="media-search"
            value={displayedQuery}
            onChange={onChange}
            onClear={onClear}
            placeholder={t("mediasViewSearch")}
            ariaLabel={t("mediasViewSearch")}
          />
        </SearchBarContainer>
        {searchQuery.length > 1 ? (
          <>
            <SearchResultCountText
              count={(matchingMedias?.length ?? 0) + (articles?.length ?? 0)}
            />
            <MediasViewSearchListMobile
              medias={matchingMedias}
              query={searchQuery}
              taskLinks={tasks.taskLinks}
              alerts={alerts}
              storeId={storeId}
              articles={articles ?? undefined}
            />
          </>
        ) : (
          <>
            <MediasCountText mediasCount={filteredMedias?.length || 0} />
            <MediasViewListMobile
              medias={filteredMedias}
              taskLinks={tasks.taskLinks}
              alerts={alerts}
            />
          </>
        )}
      </ScrollContainerEditableSettings>
      <ApplicationFooterActionBar>
        <FixaButton
          aria-label={t("addRoomSettingsString")}
          text={enableMedia ? t("addMediaString") : t("addRoomSettingsString")}
          type="primary"
          onClick={() => {
            if (enableMedia) {
              push(`/mediamaintenance/create`);
            } else {
              setShowModal(true);
            }
          }}
          style={{ width: "100%", margin: "0px 16px" }}
        />
      </ApplicationFooterActionBar>
      <CreateSettingsModal
        storeId={storeId}
        isVisible={showModal}
        onClose={(mediaId) => {
          setShowModal(false);
          if (mediaId) {
            push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
          }
        }}
      />
    </>
  );
}
