// 144 x 120

import {
  CenterContainer,
  GreyBox,
  FlexibleRowContainer,
  SizedText,
} from "../styles/styles";
import { Text } from "../Atoms/Text";

interface TwoLinedGreyBoxProps {
  headLine: string;
  bottomleftText: string;
  rightComponent: React.ReactNode;
  onClick?: () => void;
  tabIndex: number;
  role: string;
  ariaLabel: string;
}

export function TwoLinedGreyBox({
  headLine,
  bottomleftText,
  rightComponent,
  onClick,
  tabIndex,
  role,
  ariaLabel,
}: TwoLinedGreyBoxProps) {
  return (
    <GreyBox
      onClick={onClick}
      tabIndex={tabIndex}
      role={role}
      aria-label={ariaLabel}
    >
      <CenterContainer $centerText={false} style={{ gap: "24px" }}>
        <Text text={headLine} bold />
        <FlexibleRowContainer>
          <SizedText $fontSize={48} $bold>
            {bottomleftText}
          </SizedText>
          <>{rightComponent}</>
        </FlexibleRowContainer>
      </CenterContainer>
    </GreyBox>
  );
}
