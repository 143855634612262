import { MediaListItemDesktop } from "../Molecules/MediaListItemDesktop";
import { MediaListItemOngoingTasksDesktop } from "../Molecules/MediaListItemOngoingTasksDesktop";
import { TaskLinks } from "../types/taskLink";
import { AlertResponse, Media, Medias } from "../types/media";
import { MediaListItemSkeletonDesktop } from "../Molecules/MediaListItemSkeletonDesktop";
import { MediaListDesktop } from "../styles/styles";

interface MediasViewListDesktopProps {
  medias: Medias | undefined;
  alerts: AlertResponse[] | undefined;
  taskLinks: TaskLinks;
  onSaveMedia: (media: Media) => void;
  onOngoingTasksClick: () => void;
  showOngoingTasks?: boolean;
}
export function MediasViewListDesktop({
  medias,
  alerts,
  taskLinks,
  onSaveMedia,
  onOngoingTasksClick,
  showOngoingTasks = true,
}: MediasViewListDesktopProps) {
  if (!medias) {
    return (
      <MediaListDesktop id="media-view-list">
        <MediaListItemSkeletonDesktop key="medias-view-list-0" />
        <MediaListItemSkeletonDesktop key="medias-view-list-1" />
        <MediaListItemSkeletonDesktop key="medias-view-list-2" />
        <MediaListItemSkeletonDesktop key="medias-view-list-3" />
      </MediaListDesktop>
    );
  } else {
    return (
      <MediaListDesktop id="media-view-list">
        {showOngoingTasks && (
          <MediaListItemOngoingTasksDesktop
            key="medias-view-list-0"
            tasks={taskLinks}
            onClick={onOngoingTasksClick}
          />
        )}
        {medias &&
          medias.map((media, idx) => (
            <MediaListItemDesktop
              key={`medias-view-list-${idx + 1}`}
              media={media}
              alert={alerts?.find(({ mediaId }) => mediaId === media.id)}
              tasks={taskLinks.filter(({ mediaId }) => mediaId === media.id)}
              onSaveMedia={onSaveMedia}
            />
          ))}
      </MediaListDesktop>
    );
  }
}
