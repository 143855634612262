import React from "react";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import EllipsesHorizontalIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/EllipsesHorizontalIconPath";
import { TaskTabsTypes } from "../../../shared/constants/taskTabsTypes";
import {
  myTasksPath,
  openTasksPath,
} from "../../../tasks/constants/taskRoutePaths";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import trackerHelper from "../../../../../coworker-app/src/helpers/tracker";
import { useTranslation } from "@coworker/locales";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";
import {
  useFixaUID,
  useTeamId,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { DEFAULT_FILTER_VALUES } from "../../../activity/constants/defaultFilterValues";

const TasksTodosTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  height: 24px;
`;

interface TasksTodosTitleProps {
  title: string;
  tasksCount: number;
  tabType: TaskTabsTypes;
}

export const TasksTodosTitle: React.FC<TasksTodosTitleProps> = ({
  title,
  tasksCount,
  tabType,
}) => {
  const teamId = useTeamId();
  const fixaUid = useFixaUID();
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();

  const handleViewAllClick = () => {
    const path = tabType === TaskTabsTypes.OPEN ? openTasksPath : myTasksPath;

    const section = tabType === TaskTabsTypes.OPEN ? "open_tasks" : "my_tasks";

    push(path);
    const filters =
      JSON.parse(localStorage.getItem("activityFilters") as string) ??
      DEFAULT_FILTER_VALUES;

    if (path === openTasksPath) {
      filters.assignedTo = teamId;
      trackerHelper.trackOpenTasksPreview(section);
    } else if (path === myTasksPath) {
      filters.assignedTo = fixaUid;
      trackerHelper.trackMyOpenTasksPreview(section);
    }
    localStorage.setItem("activityFilters", JSON.stringify(filters));
  };
  return (
    <TasksTodosTitleContainer>
      <FixaText tagName="h2" headingSize="s">
        {title} ({tasksCount})
      </FixaText>
      {tasksCount > 0 && (
        <FixaButton
          text={t("viewAllString")}
          size="small"
          type="tertiary"
          onClick={() => handleViewAllClick()}
          iconPosition="trailing"
          ssrIcon={EllipsesHorizontalIconPath}
        />
      )}
    </TasksTodosTitleContainer>
  );
};
