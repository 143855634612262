import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";
import styles from "./article-error.module.css";
import { FixaText } from "../../wrappers/FixaText/FixaText";
import { ItemError } from "../../enums/itemError";
import React from "react";

interface ArticleErrorProps {
  openArticleErrorModal: boolean;
  onClose: (closeArticleErrorModal: boolean) => void;
  itemErrorType?: ItemError;
  query?: string;
}

export const ArticleError = ({
  openArticleErrorModal,
  onClose,
  itemErrorType,
  query
}: ArticleErrorProps) => {
  const { t } = useTranslation();
  return (
    <FixaModal
      visible={openArticleErrorModal}
      handleCloseBtn={() => {
        onClose(!openArticleErrorModal);
      }}
    >
      <FixaPrompt
        titleId="articleError"
        title=""
        header={
          <FixaModalHeader
            ariaCloseTxt={t("closeActionString")}
            title={"Article Error"}
            className={styles["header"] || ""}
          />
        }
        footer={
          <FixaModalFooter>
            <FixaButton aria-label={t("okIUnderstandString")} type={"primary"}>
              {t("okIUnderstandString")}
            </FixaButton>
          </FixaModalFooter>
        }
      >
        {itemErrorType === ItemError.ITEM_PRICE_MISSING && (
          <React.Fragment>
            <FixaText
              aria-label={t("cannotCreateTaskString")}
              tagName="h2"
              headingSize="s"
            >
              {t("cannotCreateTaskString")}
            </FixaText>
            <FixaText
              aria-label={t("edsReachedErrorMessageString")}
              className={styles["sub-text"] || ""}
            >
              {t("edsReachedErrorMessageString")}
            </FixaText>
          </React.Fragment>
        )}
        {itemErrorType === ItemError.ITEM_NOT_FOUND && (
          <FixaText tagName={"h2"} headingSize={"s"}>
            {t("noResultsForString", { query })}
          </FixaText>
        )}
      </FixaPrompt>
    </FixaModal>
  );
};
